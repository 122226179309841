import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Container,
  Menu,
  Segment,
  Dropdown,
  Sidebar,
  Icon,
  Grid,
  Sticky,
  Ref,
} from "semantic-ui-react";
import ItineraryDisplay from "./itinerary/components/ItineraryDisplay";
import Itineraries from "./itinerary/Itineraries";
import About from "./about/About";
import logo_img from "./fresh_eyes_logo_colour.svg";
import logo_project from "./logotypy.png";
import { useNavigate } from "react-router";
import useWindowDimensions from "./utils";
import "./css/extraCss.css";
import PreviewItinerary from "./itinerary/components/PreviewItinerary";
/* eslint-disable no-unused-expressions */
export const Main = (props) => {
  const [activeItem, setActiveItem] = useState("about");
  const [sidebarOpened, setSideBarOpened] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      window.location.pathname.includes("itineraries") |
      window.location.pathname.includes("preview")
    ) {
      setActiveItem("itineraries");
    } else {
      setActiveItem("about");
      navigate("");
    }
  }, [window.location.pathname]);

  const { height, width } = useWindowDimensions();

  const contextRef = useRef(null);

  return (
    <>
      <Sidebar.Pushable style={{ transform: "none" }} as={Segment}>
        <Sidebar
          as={Menu}
          style={{ position: "fixed" }}
          animation="overlay"
          icon="labeled"
          direction="right"
          inverted
          onHide={() => setSideBarOpened(false)}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item>
            <Link
              to="/"
              className={
                activeItem === "about" ? "menu_items active" : "menu_items"
              }
              style={{ marginRight: "50px" }}
              onClick={() => {
                setActiveItem("about"), setSideBarOpened(false);
              }}
            >
              teacher resources
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link
              to="/itineraries"
              className={
                activeItem === "itineraries"
                  ? "menu_items active"
                  : "menu_items"
              }
              style={{ marginRight: "100px" }}
              onClick={() => {
                setActiveItem("itineraries"), setSideBarOpened(false);
              }}
            >
              itineraries
            </Link>
          </Menu.Item>
        </Sidebar>
        <Sidebar.Pusher>
          <Menu fixed={"top"} secondary className={"menu"}>
            <Menu.Item position="left" className="logo_text">
              <Link className="logo_text" to="/">
                <img
                  src={logo_img}
                  className="logo_img"
                  alt="logo"
                  style={{ width: "145px" }}
                />
              </Link>
            </Menu.Item>
            <Menu.Item position="right">
              {width > 676 ? (
                <>
                  <Link
                    to="/"
                    className={
                      activeItem === "about"
                        ? "menu_items active"
                        : "menu_items"
                    }
                    style={{ marginRight: "50px" }}
                    onClick={() => {
                      setActiveItem("about");
                    }}
                  >
                    teacher resources
                  </Link>
                  <Link
                    to="/itineraries"
                    className={
                      activeItem === "itineraries"
                        ? "menu_items active"
                        : "menu_items"
                    }
                    style={{ marginRight: "100px" }}
                    onClick={() => {
                      setActiveItem("itineraries");
                    }}
                  >
                    itineraries
                  </Link>
                </>
              ) : (
                <Icon
                  name="sidebar"
                  className="hamburgerIcon"
                  size="big"
                  onClick={() => {
                    setSideBarOpened(true);
                  }}
                ></Icon>
              )}
            </Menu.Item>
          </Menu>

          <Routes>
            <Route path="/preview" element={<PreviewItinerary />} />
            <Route
              exact
              path="/itineraries/:id"
              element={<ItineraryDisplay />}
            />
            <Route exact path="/itineraries" element={<Itineraries />} />
            <Route exact path="/" element={<About />} />
          </Routes>

          <Segment fixed="bottom">
            <Grid centered>
              <Grid.Row>
                <Grid.Column
                  computer={8}
                  tablet={12}
                  mobile={12}
                  textAlign="center"
                  style={{ padding: "10px" }}
                >
                  <img src={logo_project} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  computer={8}
                  tablet={12}
                  mobile={12}
                  textAlign="center"
                  style={{ padding: "10px" }}
                >
                  <p
                    xmlnsCc="http://creativecommons.org/ns#"
                    xmlnsDct="http://purl.org/dc/terms/"
                  >
                    <a
                      property="dct:title"
                      rel="cc:attributionURL"
                      href="https://fresheyes.pl"
                    >
                      Fresh Eyes School Trips
                    </a>{" "}
                    is licensed under{" "}
                    <a
                      href="https://creativecommons.org/licenses/by/4.0/?ref=chooser-v1"
                      target="_blank"
                      rel="license noopener noreferrer"
                      style={{ display: "inline-block" }}
                    >
                      CC BY 4.0
                      <img
                        style={{height: '22px', marginLeft: '3px', verticalAlign:'text-bottom'}}
                        src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"
                        alt=""
                      ></img>
                      <img
                        style={{height: '22px', marginLeft: '3px', verticalAlign:'text-bottom'}}
                        src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1"
                        alt=""
                      ></img>
                    </a>
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
};

export default Main;
